const colors = ['red', 'pink', 'purple', 'blue', 'sky', 'green', 'orange', 'brown', 'grey', 'grey-blue']
const cc = colors.length
let cx = cc - 1

export const ActiveMenus = [
    { page: '1', name: 'ใช้งาน', icon: 'circle green fas' },
    { page: '0', name: 'ยกเลิก', icon: 'circle grey' }
]

export const Menus = [
    {
        page: 'core', name: 'หลัก', icon: 'tools', color: colors[cx++ % cc],
        items: [
            {
                items: [
                    { page: 'home', name: 'หน้าแรก', icon: 'home', url: '/home' },
                    { page: 'chat', name: 'ห้องแชท', icon: 'comments', url: '/chat' },
                    { page: 'support', name: 'แจ้งปัญหา', icon: 'debug', url: '/ticket' },
                    { page: 'document', name: 'คู่มือ', icon: 'books', url: '/document' },
                    { page: 'onsite', name: 'กองช่าง', icon: 'truck-monster', url: '/onsite/device' },
                ]
            }
        ]
    },

    {
        page: 'gpsiam', name: 'ระบบติดตาม', icon: 'satellite-dish', color: colors[cx++ % cc], role: '_gps',
        items: [
            {
                name: 'หน้าบ้าน',
                items: [
                    { page: 'device', name: 'รายการตัวเครื่อง', icon: 'tablet-rugged', url: '/gpsiam/device', role: 'gpsiam.device' },
                    { page: 'command', name: 'คำสั่งควบคุมเครื่อง', icon: 'rss-square', url: '/gpsiam/device_command', role: 'gpsiam.device.command' },
                    { page: 'checkpoint', name: 'เครื่องติดตามคน', icon: 'street-view', url: '/gpsiam/checkpoint', role: 'gpsiam.device' },
                    { page: 'user', name: 'Acc ผู้ใช้งาน', icon: 'user-circle', url: '/gpsiam/user', role: 'gpsiam.user' },
                    { page: 'tms', name: 'Acc Tms', icon: 'clipboard-user', url: '/gpsiam/tms', role: '__admin' },
                    { page: 'user_credit', name: 'เครดิตผู้ใช้งาน', icon: 'sms', url: '/gpsiam/user_credit', role: 'gpsiam.user.sms' },
                    //{ page: 'domain', name: 'โดเมนเว็บ', icon: 'globe', url: '/gpsiam/domain', role: '__admin' }
                ]
            },
            {
                name: 'หลังบ้าน',
                items: [
                    { page: 'customer', name: 'รายชื่อลูกค้า', icon: 'user-crown', url: '/gpsiam/customer', role: 'gpsiam.customer' },
                    { page: 'gps', name: 'รายชื่อ GPS', icon: 'location-circle', url: '/gpsiam/product', role: 'product.gps' },
                    { page: 'dvr', name: 'รายชื่อ DVR', icon: 'camcorder', url: '/gpsiam/product_dvr', role: 'product.dvr' },
                    { page: 'etc', name: 'อุปกรณอื่นๆ', icon: 'speakers', url: '/gpsiam/product_etc', role: '__admin' },
                    { page: 'transfer', name: 'ย้ายเครื่อง', icon: 'person-carry', url: '/gpsiam/device_transfer', role: 'product.transfer' },
                    { page: 'reseller', name: 'ตัวแทน/นายหน้า', icon: 'user-tie', url: '/gpsiam/reseller', role: 'gpsiam.reseller' },
                ]
            },
            {
                items: [
                    { page: 'sim', name: 'SIM', icon: 'sim-card', url: '/gpsiam/sim', role: 'gpsiam.sim' },
                    { page: 'dlt', name: 'ข้อมูลขนส่ง', icon: 'whale', url: '/gpsiam/dlt', role: 'gpsiam.dlt' },
                    { page: 'dltsend', name: 'ข้อมูลส่งต่อ', icon: 'fish', url: '/gpsiam/forward', role: 'gpsiam.dlt' }
                ]
            },
            {
                items: [
                    { page: 'content', name: 'เนื้อหา/เอกสาร', icon: 'book', url: '/gpsiam/document', role: 'gpsiam.content' }
                ]
            }
        ]
    },

    {
        page: 'account', name: 'บัญชี', icon: 'usd-circle', color: colors[cx++ % cc], role: '_account',
        items: [
            {
                name: 'งานหลังการขาย',
                items: [
                    { page: 'setup', name: 'งานติดตั้ง', icon: 'hammer', url: '/account/pay_setup', role: 'account.sale.setup' },
                    { page: 'pay.month', name: 'ค่ารายเดือน', icon: 'comment-dollar', url: '/account/pay.month', urlMatch: '/account/pay.month', role: 'account.sale.interval' },
                    { page: 'pay', name: 'ค่ารายปี', icon: 'comment-dollar', url: '/account/pay', urlMatch: '/account/pay', role: 'account.sale.interval' },
                    { page: 'pay3', name: 'ค่ารายปีล่วงหน้า', icon: 'comments-dollar', url: '/account/pay3', role: 'account.sale.interval' },
                    { page: 'etc', name: 'อุปกรณ์อื่นๆ', icon: 'toolbox', url: '/account/pay_etc', role: 'account.sale.etc' },
                    { page: 'service', name: 'Service ตัวเครื่อง', icon: 'screwdriver', url: '/account/pay_service', role: 'account.sale.service' },
                    { page: 'average', name: 'เฉลี่ยวันครบกำหนด', icon: 'calendar-star', url: '/account/pay_average', role: 'account.sale.average' },
                    { page: 'drop', name: 'แก้ไขวันชำระเงิน', icon: 'calendar-edit', url: '/account/pay_drop', role: 'account.sale.paydrop' }
                ]
            },
            {
                name: 'การเงิน',
                items: [
                    { page: 'transaction', name: 'รายการเงินเข้า', icon: 'usd-circle', url: '/account/transaction', role: 'account.transection' },
                    { page: 'day_reserve', name: 'เงินสำรอง', icon: 'hand-holding-usd', url: '/account/day_reserve', role: 'account.reserve' },
                    { page: 'event', name: 'ปฏิทินกิจกรรม', icon: 'calendar-alt', url: '/account/event', role: '_account' }
                ]
            },
            {
                name: 'พนักงาน',
                items: [
                    { page: 'list', name: 'รายชื่อพนักงาน', icon: 'user-secret', url: '/account/employee', urlMatch: '/account/employee' },
                    { page: 'salary', name: 'เงินเดือน', icon: 'coin', url: '/account/employee_salary', role: 'account.employee.salary' },
                    { page: 'contact', name: 'เบอร์ติดต่อ', icon: 'mobile-alt', url: '/account/employee_contact' }
                ]
            }
        ]
    },

    {
        page: 'sale', name: 'งานขาย', icon: 'ballot-check', color: colors[cx++ % cc], role: 'sale',
        items: [
            {
                name: 'ใบเสนอราคา',
                items: [
                    { page: 'job', name: 'ใบเสนอราคา', icon: 'file-user', url: '/sale/job/quotation' },
                ]
            },
            {
                items: [
                    { page: 'product', name: 'รายการสินค้า', icon: 'box-usd', url: '/sale/product' },
                    { page: 'performance', name: 'ยอดขาย', icon: 'salad', url: '/sale/performance' }
                ]
            },
            {
                items: [
                    { page: 'company', name: 'บริษัท', icon: 'building', url: '/sale/company' },
                    { page: 'sale_condition', name: 'เงื่อนไขเสนอราคา', icon: 'ballot-check', url: '/sale/sale_condition' }
                ]
            },
        ]
    },

    {
        page: 'crm', name: 'Customer Service', icon: 'users-crown', color: colors[cx++ % cc], role: '__admin',
        items: [
            {
                items: [
                    { page: 'case_management', name: 'Case management', icon: 'book-spells', url: '/crm/case_management' },
                ]
            },
        ]
    },

    {
        page: 'work', name: 'งานช่าง', icon: 'briefcase', color: colors[cx++ % cc], role: '_work',
        items: [
            {
                items: [
                    { page: 'tms', name: 'ตารางงาน', icon: 'calendar-alt', url: '/work/tms' },
                    { page: 'work', name: 'รายการงาน', icon: 'list-alt', url: '/work/list' }
                ]
            },

            {
                items: [
                    { page: 'search', name: 'ค้นหางาน', icon: 'search', url: '/work/search', urlMatch: '/work/search' },
                    { page: 'search_hold', name: 'งานที่เก็บไว้', icon: 'pause', url: '/work/search/hold' },
                    { page: 'search_paytype-10', name: 'โอนเงิน', icon: 'money-bill-wave', url: '/work/search/paytype-10' },
                    { page: 'search_paytype-20', name: 'เก็บเช็ค', icon: 'money-check-alt', url: '/work/search/paytype-20' },
                    { page: 'search_paytype-30', name: 'เงินสด', icon: 'coin', url: '/work/search/paytype-30' },
                    { page: 'search_paytype-40', name: 'วางบิล', icon: 'file-invoice-dollar', url: '/work/search/paytype-40' },
                    { page: 'search_paytype-50', name: 'ไม่เก็บ', icon: 'hand-holding-usd', url: '/work/search/paytype-50' },
                    { page: 'search_expire', name: 'ครบกำหนดชำระ', icon: 'calendar-times', url: '/work/search/expire' },
                ]
            },

            {
                items: [
                    { page: 'certificate', name: 'หนังสือรับรอง', icon: 'file-certificate', url: '/work/certificate' },
                    { page: 'invoice', name: 'ใบเสร็จ', icon: 'file-invoice-dollar', url: '/work/invoice' },
                ]
            },
        ]
    },

    {
        page: 'stock', name: 'สต๊อค', icon: 'box', color: colors[cx++ % cc], role: '_stock',
        items: [
            {
                items: [
                    { page: 'house', name: 'คลังวัสดุ', icon: 'house', url: '/stock/house' },
                    { page: 'summary', name: 'สรุปสต๊อค', icon: 'bring-front', url: '/stock/summary' }
                ]
            },

            {
                name: 'ข้อมูลพื้นฐาน',
                items: [
                    { page: 'config_stock', name: 'คลังสินค้า', icon: 'warehouse', url: '/stock/config/stock' },
                    { page: 'config_car', name: 'รถ', icon: 'car-mechanic', url: '/stock/config/car' },
                    { page: 'config_item', name: 'สินค้า', icon: 'box-usd', url: '/stock/config/item' },
                    { page: 'config_stuff', name: 'วัสดุสิ้นเปลือง', icon: 'layer-group', url: '/stock/config/stuff' },
                    { page: 'config_set', name: 'ชื่อชุดวัสดุ', icon: 'object-group', url: '/stock/config/set' }
                ]
            },
        ]
    },

    {
        page: 'check', name: 'เครื่องมือ/ตรวจสอบ', icon: 'tools', color: colors[cx++ % cc],
        items: [
            {
                items: [
                    { page: 'monitor', name: 'Monitor', icon: 'monitor-heart-rate', url: '/check/monitor', role: '__admin' },
                    { page: 'archive', name: 'Archive Log', icon: 'file-archive', url: '/check/archive' },
                    { page: 'calculator', name: 'Calculator', icon: 'calculator-alt', url: '/check/calculator', role: '__admin' },
                    { page: 'test', name: 'Test', icon: 'cog', url: '/check/test', role: '__admin' },
                    //{ page: 'checkpoint', name: 'ติดตามคน', icon: 'street-view', url: '/check/checkpoint', role: '__admin' },
                ]
            },
            {
                items: [
                    { page: 'device', name: 'เครื่องติดตาม', icon: 'tablet-rugged', url: '/check/device' },
                    { page: 'chart', name: 'AD Chart', icon: 'chart-line', url: '/check/ad_chart' },
                    //{ page: 'post', name: 'รถไปรษณีย์', icon: 'mailbox', url: '/check/gov/post', role: '__admin' },
                ]
            },
            {
                items: [
                    { page: 'address', name: 'ที่อยู่', icon: 'vihara', url: '/check/address', role: '__admin' },
                    { page: 'map_userpoi', name: 'POI ผู้ใช้งาน', icon: 'map-marked-alt', url: '/check/map_userpoi' },
                    { page: 'map', name: 'แผนที่', icon: 'map', url: '/check/map' },
                    { page: 'map_geofence', name: 'GeoFence', icon: 'globe-stand', url: '/check/map_geofence', role: '__admin' },
                    { page: 'poi', name: 'Poi', icon: 'map-marker-alt', url: '/check/poi' },
                ]
            },
        ]
    }

    //{ id: 60, key: 'meta', name: 'ข้อมูลพื้นฐาน', icon: 'database', color: 'green', role: 'meta' },
    // {id: 90, key: 'develop', name: 'ห้องทดลอง', icon: 'vial', color: 'grey-blue'}
    // { id: 1, key: 'admin', name: 'Admin Console', icon: 'comet', color: 'black', role: '__admin' },
]

export const MenusOnsite = [
    { page: 'onsite', name: 'กองช่าง', icon: 'truck-monster', url: '/onsite/device' },
    { page: 'work', name: 'รายการงาน', icon: 'list-alt', url: '/onsite/work' }
]

export const MenuAcp = [
    { page: 'user_access', name: 'เครื่องผู้ใช้งาน', icon: 'mobile-android-alt', url: '/acp/user_access' },
    { page: 'user', name: 'ผู้ใช้งาน', icon: 'user', url: '/acp/user' },
    { page: 'user_group', name: 'กลุ่มผู้ใช้งาน', icon: 'users-class', url: '/acp/user_group' },
    { page: 'branch', name: 'สาขา', icon: 'farm', url: '/acp/branch' },
    { page: 'car', name: 'ข้อมูลรถ', icon: 'car', url: '/acp/car' },
    { page: 'meta', name: 'ข้อมูลพื้นฐาน', icon: 'comet', url: '/acp/meta' },
]

export default Menus
